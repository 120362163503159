import $ from 'jquery';
import Cookies from 'js-cookie';
window.$ = $;

export default () => {

    console.log('custom')
    $('.au-header__menu ul li a, .au-hero .vertical-nav, .au-hero .button, .au-section-one .button, .au-section-three .button, .au-header__menu-mobile ul li a').on('click', function(e) {
        console.log('click')
        e.preventDefault();
        var targetSection = $($(this).attr('href')).offset().top - 30;
        $('html, body').animate({
            scrollTop: targetSection,
        },500,'linear');
    });

    function createCookies() {
        console.log('cook')
        $('.cookie-bar').show();
        $('button.cookie-bar__button').click(function() {
            $('.cookie-bar').fadeOut();
            Cookies.set('cookie-info', 'hide');
        });
        if(Cookies.get('cookie-info')) $('.cookie-bar').hide();
    };
    createCookies();

    $('.menu-icon').click(function(){
        $('.au-header__menu-mobile-container').addClass('active');
    });
    $('.au-header__menu-mobile .close-button, .au-header__menu-mobile ul li a').click(function(){
        $('.au-header__menu-mobile-container').removeClass('active');
    });

    // $('p').each(function() {
    //     var textReplace = $(this).html();
    //     var lettersToReplace = ["a","i","o","u","w","z","A","I","O","U","W","Z"];
    //     var arrayLength = lettersToReplace.length;
    //     for (var i = 0; i < arrayLength; i++) {
    //         var textSplit = textReplace.split(' ' + lettersToReplace[i] + ' ');
    //         var textReplace = textSplit.join(' ' + lettersToReplace[i] + '&nbsp;');
    //     }
    //     $(this).empty();
    //     $(this).html(textReplace);
    // });

};
