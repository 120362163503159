import "./src/styles/scss/index.scss"; 
import './node_modules/swiper/js/swiper.min.js';
import 'foundation-sites';
import $ from 'jquery';
import init from'./src/js/custom';

// export eq module.exports
export const onInitialClientRender = () => {
    $(document).foundation();
    init();
  }